body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "HelveticaNeue-Light";
  src: local("HelveticaNeue"),
    url("./fonts/HelveticaNeue Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Thin";
  src: local("HelveticaNeue"),
    url("./fonts/HelveticaNeue Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
